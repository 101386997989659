const checkbox = document.querySelector(".js-agreement-checkbox");
function handleCheckboxChange(event) {
  const node = event.target;
  syncCheckboxState(node.checked);
}

function syncCheckboxState(checked) {
  const submitBtnNode = document.querySelector(".js-agreement-submit-btn");
  if (checked) {
    submitBtnNode.classList.remove("is-disabled");
  } else {
    submitBtnNode.classList.add("is-disabled");
  }
}

document.addEventListener("readystatechange", function () {
  if (checkbox) {
    checkbox.addEventListener("change", handleCheckboxChange);
    syncCheckboxState(checkbox.checked);
  }
});