let removeButton = $(".remove-button");
let errorMessage = $(".anytime-consultation-image-error-message");

let dropzone = $(".anytime-consultation-image");
dropzone.ezdz({
  className: "upload-dropzone",
  text: "",
  accept: function () {
    errorMessage.hide();
    removeButton.show();
  },
  reject: function(file) {
    let message = `${file.extension}形式のファイルはアップロードできません。<br>アップロードできるファイルタイプは .jpg .png .gif です。`;
    errorMessage.html(message);
    errorMessage.show();
  }
});

// initialize removeButton based on the presence of image
if (dropzone.data("value")) {
  removeButton.show();
} else {
  removeButton.hide();
}

// delete preview and cache
removeButton.on("click", function () {
  dropzone.ezdz("preview", null);
  $("#cache_image").remove();
  removeButton.hide();
});

let spinner = $(".spinner");
spinner.hide();
$(".common-btn-main-action").on("click", function () {
  spinner.show();
});
